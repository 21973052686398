import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import * as styles from './index.module.css';

const LinkButtonGhostPrimary = ({ to, label }) => {
  return (
    <div className={styles['root']}>
      <Link className={styles['label']} to={to}>
        {label}
      </Link>
    </div>
  );
};

LinkButtonGhostPrimary.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default LinkButtonGhostPrimary;
