import React from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import PublicRoot from '../../components/PublicRoot';
import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import ContainerDefault from '../../components/ContainerDefault';
import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';
import ArticleList from '../../organisms/ArticleList';
import SeriesList from '../../organisms/SeriesList';
import EventList from '../../organisms/EventList';

import TagLinkBadgeGroup from '../../molecules/common/TagLinkBadgeGroup';

import SectionHeading from '../../molecules/common/SectionHeading';
import LinkButtonGhostPrimaryLarge from '../../atoms/LinkButtonGhostPrimaryLarge';

import * as styles from './index.module.css';

const IndexTemplate = ({ pageContext }) => {
  const {
    pagePath,
    recentArticles,
    recentTags,
    recentSeries,
    recentEvents,
  } = pageContext;

  return (
    <PublicRoot>
      <SEO path={pagePath} title="TORCH" />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <section>
              <h1 className="sr-only">ホーム</h1>

              <section className={styles['recentArticlesSection']}>
                <ContainerDefault>
                  <div className={styles['sectionHeadingWrapper']}>
                    <SectionHeading heading="最新記事" />
                  </div>

                  <div className={styles['articleListWrapper']}>
                    <ArticleList articles={recentArticles} eager="true" />
                  </div>

                  <InlineBlockWrapper className={styles['showAllLinkWrapper']}>
                    <LinkButtonGhostPrimaryLarge
                      to="/article"
                      label="記事一覧"
                    />
                  </InlineBlockWrapper>
                </ContainerDefault>
              </section>

              <section className={styles['recentTagsSection']}>
                <ContainerDefault>
                  <div className={styles['sectionHeadingWrapper']}>
                    <SectionHeading heading="最近更新したタグ" />
                  </div>

                  <div>
                    <TagLinkBadgeGroup tags={recentTags} />
                  </div>
                </ContainerDefault>
              </section>

              <section className={styles['recentSeriesSection']}>
                <ContainerDefault>
                  <div className={styles['sectionHeadingWrapper']}>
                    <SectionHeading heading="おすすめシリーズ" />
                  </div>

                  <div className={styles['seriesListWrapper']}>
                    <SeriesList series={recentSeries} />
                  </div>

                  <InlineBlockWrapper className={styles['showAllLinkWrapper']}>
                    <LinkButtonGhostPrimaryLarge
                      to="/series"
                      label="シリーズ一覧"
                    />
                  </InlineBlockWrapper>
                </ContainerDefault>
              </section>

              <section className={styles['recentEventSection']}>
                <ContainerDefault>
                  <div className={styles['sectionHeadingWrapper']}>
                    <SectionHeading heading="イベントカレンダー" />
                  </div>

                  <div className={styles['eventListWrapper']}>
                    <EventList events={recentEvents} />
                  </div>

                  <InlineBlockWrapper className={styles['showAllLinkWrapper']}>
                    <LinkButtonGhostPrimaryLarge
                      to="/event"
                      label="イベント一覧"
                    />
                  </InlineBlockWrapper>
                </ContainerDefault>
              </section>
            </section>
          </main>
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </PublicRoot>
  );
};

export default IndexTemplate;
