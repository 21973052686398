import React, { useState, useEffect } from 'react';

import EventListCard from '../../molecules/event/EventListCard';

import * as styles from './index.module.css';

const EventList = ({ events }) => {
  const [key, setKey] = useState(`event_list_${new Date().getTime()}`);

  useEffect(() => {
    setKey(`event_list_${new Date().getTime()}`);
  }, []);

  const filtered = events.filter(event => {
    return (
      new Date(event.date) >= new Date(new Date().toISOString().slice(0, 10))
    );
  });

  if (filtered.length > 0) {
    return (
      <div key={key}>
        <p className={styles['note']}>
          ※開催日を掲載しています。事前に参加申し込みが必要な場合がありますので、リンク先のイベント詳細をご確認ください
        </p>
        <ul className={styles['list']}>
          {filtered.map(event => (
            <li className={styles['listItem']} key={event.id}>
              <EventListCard event={event} />
            </li>
          ))}
        </ul>
      </div>
    );
  } else {
    return (
      <div>
        <p>現在イベントはありません。</p>
      </div>
    );
  }
};

export default EventList;
