// extracted by mini-css-extract-plugin
export var articleListWrapper = "index-module--article-list-wrapper--I5ZMg";
export var eventListWrapper = "index-module--event-list-wrapper--o-ywK";
export var main = "index-module--main--F87Uk";
export var recentArticlesSection = "index-module--recent-articles-section--7XGmH";
export var recentEventSection = "index-module--recent-event-section--nCIlg";
export var recentSeriesSection = "index-module--recent-series-section--YDBU7";
export var recentTagsSection = "index-module--recent-tags-section--EcsoK";
export var sectionHeadingWrapper = "index-module--section-heading-wrapper--PCHba";
export var seriesListWrapper = "index-module--series-list-wrapper--C-a9a";
export var showAllLinkWrapper = "index-module--show-all-link-wrapper--tqdUs";