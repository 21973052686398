import React, { useState, useCallback } from 'react';
import dayjs from 'dayjs';

import * as styles from './index.module.css';

const EventListCard = ({ event }) => {
  const [linkStyle, setLinkStyle] = useState({});

  const onLinkMouseEnter = useCallback(() => {
    setLinkStyle({
      opacity: 0.75,
    });
  }, []);

  const onLinkMouseLeave = useCallback(() => {
    setLinkStyle({});
  }, []);

  const dateString = dayjs(event.date).format('YYYY/M/D');

  return (
    <div className={styles['root']}>
      <a
        href={event.url}
        target="_blank"
        rel="noopener noreferrer"
        className={styles['link']}
        style={linkStyle}
        onMouseEnter={onLinkMouseEnter}
        onMouseLeave={onLinkMouseLeave}
      >
        <div className={styles['contentWrapper']}>
          <div className={styles['dateWrapper']}>
            <time dateTime={event.date}>
              <span className={styles['date']}>{dateString}</span>
            </time>
          </div>

          <div className={styles['titleWrapper']}>
            <span className={styles['title']}>{event.title}</span>
          </div>

          <div className={styles['organizerWrapper']}>
            <span className={styles['organizer']}>{event.organizer}</span>
          </div>
        </div>
      </a>
    </div>
  );
};

export default EventListCard;
